<template>
  <header>
    <h1>Kalkulator</h1>
    <h4>przeliczanie kwot podatkowych</h4>
  </header>
  <div class="row">
    <div class="col-50 ma">
      <base-card>
        <template #header>Kalkulator podatkowy</template>
        <template #content>
          <tax-calc></tax-calc>
        </template>
      </base-card>
    </div>
  </div>
</template>

<script>
import TaxCalc from "../components/taxCalc/TaxCalc.vue";
export default {
  components: { TaxCalc },
};
</script>

<style lang="scss" scoped>
@import "../_base.scss";
</style>